<template>
  <div class="center brokers" id="brokers">
    <table
      class="brokers__table"
      v-if="brokersListLoaded && brokersList.length"
    >
      <thead>
        <tr>
          <td class="" v-for="(title, index) in tableTitles" :key="index">
            <button
              class="sort_btn"
              @click="
                title.toLowerCase() !== 'contact' ? setSortParam(title) : null
              "
            >
              {{ title }}
            </button>
          </td>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="line in brokersList"
          :key="line.id"
          :class="[
            showReviewButton(line) ? 'await' : '',
            line.disabled ? 'disabled' : '',
          ]"
        >
          <td class="brokers__table-when">
            {{ line.changedAt | dateFormat }}
          </td>
          <td class="brokers__table-broker">
            <router-link :to="{ name: 'brokerEdit', params: { id: line.id } }">
              {{ line.name }}

              <span class="badge badge--error" v-if="line.locked">
                locked
              </span>
              <span class="badge" v-if="line.noImportantFields"> partial </span>
            </router-link>
            <span class="badge" v-if="line.badge"> {{ line.badge }} </span>
          </td>

          <td class="brokers__table-sub-brokers">
            <div class="brokers__table-sub-brokers-wrap">
              <div v-for="(item, index) in line.relatedBrokers" :key="index">
                <router-link
                  :to="{ name: 'brokerEdit', params: { id: item.id } }"
                >
                  {{ item.name }}
                  <span class="badge badge--error" v-if="item.locked">
                    locked
                  </span>
                </router-link>
              </div>
            </div>
          </td>

          <td class="brokers__table-anchor">
            <router-link :to="{ name: 'brokerEdit', params: { id: line.id } }">
              {{ line.anchor }}
            </router-link>
          </td>
          <td class="brokers__table-company">
            {{ line.company }}
          </td>
          <td class="brokers__table-contact">
            <Hint
              :hintText="line.contactsForAdmin"
              v-if="line.contactsForAdmin"
            />
          </td>
          <td class="brokers__table-status">
            <div class="status">
              <img
                src="@/assets/svg/ico-review.svg"
                alt="#"
                v-if="line.reviewed === 'done'"
              />
              <img
                src="@/assets/svg/ico-cancel.svg"
                alt="#"
                v-else-if="line.reviewed === 'canceled'"
              />
              <img
                src="@/assets/svg/ico-await.svg"
                alt="#"
                v-else-if="
                  line.reviewed === 'none' ||
                  line.reviewed === 'wait_after_update'
                "
              />
              {{
                line.reviewed === "done"
                  ? "Reviewed"
                  : line.reviewed === "canceled"
                  ? "Canceled"
                  : "Awaiting review"
              }}
            </div>

            <router-link
              :to="{ name: 'brokerReview', params: { id: line.id } }"
              v-if="showReviewButton(line)"
              class="btn btn--accent"
            >
              Review
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="empty-text"
      v-else-if="brokersListLoaded && !brokersList.length"
    >
      <h2 style="font-size: 24px">Brokers not created yet</h2>
      <p v-if="userType !== 'manager'">Please use your link or contacts us.</p>
    </div>
    <div class="empty-text" v-else-if="!brokersListLoaded">
      <h2 style="font-size: 24px">Loading...</h2>
    </div>
  </div>
</template>

<script>
import Hint from "@/components/Hint.vue";
import { brokerList, getUserBrokers } from "@/components/graphQL/brokerQueries";
import { mapGetters } from "vuex";

export default {
  components: {
    Hint,
  },
  data() {
    return {
      tableTitles: [
        "When",
        "Broker",
        "Sub-brokers",
        "Anchor",
        "Company",
        "Contact",
        "Status",
      ],
      updated: false,
      userType: localStorage.getItem("Mode"),
      brokersListLoaded: false,
    };
  },
  computed: {
    ...mapGetters([]),

    brokersList() {
      if (this.brokerList) {
        return this.brokerList;
      } else if (this.getUserBrokers) {
        return this.getUserBrokers;
      } else {
        return [];
      }
    },
  },
  methods: {
    showReviewButton(broker) {
      return (
        (broker.reviewed === "none" ||
          broker.reviewed === "wait_after_update") &&
        this.userType === "manager"
      );
    },
    setSortParam(param) {
      event.target
        .closest(".brokers__table")
        .querySelectorAll(".sort_btn")
        .forEach((item) => {
          if (item !== event.target) {
            item.classList.remove("sort_btn--up");
            item.classList.remove("sort_btn--down");
          }
        });
      let order = event.target.classList.contains("sort_btn--up")
        ? "up"
        : "down";
      if (order === "up") {
        event.target.classList.add("sort_btn--down");
        event.target.classList.remove("sort_btn--up");
        order = "up";
        this.brokerList.reverse();
      } else {
        event.target.classList.remove("sort_btn--down");
        event.target.classList.add("sort_btn--up");
        order = "down";
        if (param.toLowerCase() === "when") {
          this.sortByWhen();
        } else if (param.toLowerCase() === "broker") {
          this.sortByBroker();
        } else if (param.toLowerCase() === "company") {
          this.sortByCompany();
        } else if (param.toLowerCase() === "anchor") {
          this.sortByAnchor();
        } else if (param.toLowerCase() === "status") {
          this.sortByStatus();
        }
      }
    },
    sortByWhen() {
      this.brokersList.sort(function (a, b) {
        if (a.disabled > b.disabled) {
          return 1;
        }
        if (a.disabled < b.disabled) {
          return -1;
        }
        if (a.changedAt < b.changedAt) {
          return 1;
        }
        if (a.changedAt > b.changedAt) {
          return -1;
        }
        return 0;
      });
    },
    sortByBroker() {
      this.brokersList.sort(function (a, b) {
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1;
        }
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
    sortByAnchor() {
      this.brokersList.sort(function (a, b) {
        if (a.anchor.toLowerCase() > b.anchor.toLowerCase()) {
          return 1;
        }
        if (a.anchor.toLowerCase() < b.anchor.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
    sortByCompany() {
      this.brokersList.sort(function (a, b) {
        let first =
          !a.company || a.company === " " ? "" : a.company.toLowerCase();
        let second =
          !b.company || b.company === " " ? "" : b.company.toLowerCase();
        if (first > second) {
          return 1;
        }
        if (first < second) {
          return -1;
        }
        return 0;
      });
    },
    sortByStatus() {
      this.brokersList.sort(function (a, b) {
        if (a.reviewed < b.reviewed) {
          return 1;
        }
        if (a.reviewed > b.reviewed) {
          return -1;
        }
        return 0;
      });
    },
  },
  apollo: {
    brokerList: {
      query: brokerList,
      skip() {
        return localStorage.getItem("Mode") !== "manager";
      },
      result({ loading }) {
        this.brokersListLoaded = loading ? false : true;
        this.sortByWhen();
        document.querySelector(".sort_btn")
          ? document.querySelector(".sort_btn").classList.add("sort_btn--up")
          : null;
      },
    },
    getUserBrokers: {
      query: getUserBrokers,
      skip() {
        return localStorage.getItem("Mode") !== "client";
      },
      result({ loading }) {
        this.brokersListLoaded = loading ? false : true;
      },
    },
  },
  updated: function () {
    this.$nextTick(function () {
      if (!this.updated) {
        this.$apollo.queries.brokerList.refresh();
        this.updated = true;
      }
    });
  },
};
</script>
