<template>
  <div class="wrapper">
    <Header />
    <main class="main" id="main">
      <Brokers />
    </main>
    <Footer />
    <ScrollTop v-if="showScrollTop" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Brokers from "@/components/Brokers.vue";
import ScrollTop from "@/components/ScrollTop";
import { mapMutations } from "vuex";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Brokers,
    ScrollTop,
  },
  computed: {
    showScrollTop: () => {
      if (document.querySelector("body").offsetHeight < window.innerHeight) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations(["isFormChangedDetect"]),
  },
  created() {
    this.isFormChangedDetect(false);
  },
};
</script>
