<template>
  <div class="hint">
    <button class="hint_toggler" @click="showHint">
      <img src="@/assets/img/sobaka.png" alt="@" />
    </button>
    <div class="hint_content">
      {{ hintText }}
      <button class="hint_close" @click="hideHint"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hintText: {
      type: String,
      required: true,
    },
  },
  methods: {
    showHint() {
      document.querySelectorAll(".hint_content").forEach((item) => {
        item.classList.remove("active");
      });

      let hint = event.target.closest(".hint").querySelector(".hint_content");

      hint.classList.add("active");
      this.resetPosition(hint);
      this.countPosition(hint);
    },
    hideHint() {
      let hint = event.target.closest(".hint").querySelector(".hint_content");

      hint.classList.remove("active");

      this.resetPosition(hint);
    },
    countPosition(elem) {
      if (elem.getBoundingClientRect().top < 200) {
        elem.classList.add("hint_content--bottom");
      } else {
        elem.classList.add("hint_content--top");
      }
      if (elem.getBoundingClientRect().left < 350) {
        elem.classList.add("hint_content--right");
      } else {
        elem.classList.add("hint_content--left");
      }
    },
    resetPosition(elem) {
      elem.classList.remove("hint_content--left");
      elem.classList.remove("hint_content--top");
      elem.classList.remove("hint_content--right");
      elem.classList.remove("hint_content--bottom");
    },
  },
};
</script>
